/* eslint-disable no-unused-vars */
import 'aos/dist/aos.css'
import { CSSPlugin, gsap } from 'gsap'
import React, { useCallback, useEffect, Suspense, lazy } from 'react'
import './App.css'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate } from 'react-router-dom'
import FranceFlag from './assets/svgs/flag-france.svg'
import GermanyFlag from './assets/svgs/flag-germany.svg'
import SpainFlag from './assets/svgs/flag-spain.svg'
import EngFlag from './assets/svgs/flag-uk.svg'
import { getCookie, setCookie } from './utils/cookies'
import Privacy from './pages/Privacy'
import Eula from './pages/Eula'
import ReturnPolicy from './pages/ReturnPolicy'
import Navbar from './components/common/Navbar'
import GettingStarted from './pages/GettingStarted'
import './pages/GettingStartedStyles.css'

import FeaturesPage from './pages/FeaturesPage'
import ReferralProgramPage from './pages/ReferralProgramPage'
import BlogsPage from './pages/BlogsPage'
import PreSaleOffer from './pages/PreSaleOffer'
import PrivacyStatement from './pages/PrivacyStatement'
import Unsubscribe from './pages/Unsubscribe'


const Home = lazy(() => import('./pages/Home'))
const Cookie = lazy(() => import('./pages/Cookie'))
const Conditions = lazy(() => import('./pages/Conditions'))
const Temps = lazy(() => import('./pages/Temps'))
const ThankYou = lazy(() => import('./components/common/ThankYou'))

import { logEvent } from 'firebase/analytics'
import { analytics, firestore } from './firebase'

export const languagePptions = [
    { value: 'en', label: 'EN', icon: EngFlag },
    { value: 'de', label: 'DE', icon: GermanyFlag },
    { value: 'fr', label: 'FR', icon: FranceFlag },
    { value: 'es', label: 'ES', icon: SpainFlag },
]

gsap.registerPlugin(CSSPlugin)

function App() {
    const navigate = useNavigate()
    const { i18n } = useTranslation()
    const [activeLanguage, setActiveLanguage] = useState(languagePptions[0])
    const [showSignUpPopup, setShowSignUpPopup] = useState(false)
    
    // Add this function to check URL parameters
    const checkURLParams = useCallback(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('signup') === 'true') {
            setShowSignUpPopup(true);
        }
    }, []);

    const handleRedirect = useCallback(
        (userLang) => {
            if (['en', 'de', 'fr', 'es'].includes(userLang)) {
                navigate(`/${userLang}`)
            } else {
                navigate(`/en`)
            }
        },
        [navigate]
    )
    // For tracking website visits from meta ad campaigns
    useEffect(() => {
        // Get URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const utmSource = urlParams.get('utm_source');
        const utmMedium = urlParams.get('utm_medium');
        const utmCampaign = urlParams.get('utm_campaign');
        const utmContent = urlParams.get('utm_content');
        
        // If UTM parameters exist, log them to Firebase
        if (utmSource || utmMedium || utmCampaign) {
          
          logEvent(analytics, 'campaign_details', {
            campaign_source: utmSource || '(not set)',
            campaign_medium: utmMedium || '(not set)',
            campaign_name: utmCampaign || '(not set)',
            campaign_content: utmContent || '(not set)'
          });
        }
      }, []);

    useEffect(() => {
        // Check URL parameters when component mounts
        checkURLParams();

        var manuallySetLanguage = getCookie('user_language_preference')
        if (!manuallySetLanguage) {
            var FullUserLang = i18n.resolvedLanguage
            var userLang = FullUserLang.slice(0, 2)
            setCookie('user_language_preference', userLang, 30)
        } else {
            const activeLang = languagePptions.find(
                (lang) => lang.value === manuallySetLanguage
            )
            var htmlElement = document.querySelector('html')
            htmlElement.lang = activeLang.value
            setActiveLanguage(activeLang)
        }
    }, [activeLanguage, handleRedirect, i18n.resolvedLanguage, checkURLParams])

    // Add listener for route changes
    useEffect(() => {
        const handleRouteChange = () => {
            checkURLParams();
        };

        window.addEventListener('popstate', handleRouteChange);
        return () => {
            window.removeEventListener('popstate', handleRouteChange);
        };
    }, [checkURLParams]);

    return (
        <>
            <div className={showSignUpPopup ? 'overlay-blur' : ''}>
                <Navbar setShowSignUpPopup={setShowSignUpPopup} />
                <main className="app dark">
                    <Suspense fallback={<div>Loading...</div>}>

                        <Routes>
                            <Route path="/temp" element={<Temps />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/eula" element={<Eula />} />
                            <Route path="/cookie" element={<Cookie />} />
                            <Route path="/conditions" element={<Conditions />} />
                            <Route path="/refunds" element={<ReturnPolicy/>} />
                            <Route path="/how-to-use" element={<GettingStarted />} />
                            <Route path="/features" element={<FeaturesPage />} />
                            <Route path="/referral-program" element={<ReferralProgramPage />} />
                            <Route path="/presale" element = {<PreSaleOffer />} />
                            <Route path="/public_video_privacy" element = {<PrivacyStatement />} />
                            <Route path={`/blogs/${activeLanguage.value}`} element={<BlogsPage />} />
                            <Route path="/unsubscribe" element={<Unsubscribe />} />
                            <Route
                                path="/*"
                                element={
                                    <Home
                                        showSignUpPopup={showSignUpPopup}
                                        setShowSignUpPopup={setShowSignUpPopup}
                                    />
                                }
                            />
                        </Routes>
                    </Suspense>
                </main>
            </div>
        </>
    )
}

export default App