import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Unsubscribe.css';

const Unsubscribe = () => {
  const [loading, setLoading] = useState(false);
  const [preferences, setPreferences] = useState({});
  const [saving, setSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [inputEmail, setInputEmail] = useState("");
  const [activeEmail, setActiveEmail] = useState(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedEmail = params.get('email');
  const urlEmail = encodedEmail ? decodeURIComponent(encodedEmail) : null;

  // Your cloud function URL
  const cloudFunctionUrl = 'https://europe-west1-intertennis-landing.cloudfunctions.net/manageEmailPreferences';
  
  useEffect(() => {
    if (urlEmail) {
      setActiveEmail(urlEmail);
      fetchPreferences(urlEmail);
    } else {
      // Just initialize without showing error
      setLoading(false);
    }
  }, [urlEmail]);

  const fetchPreferences = async (emailToFetch) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch(cloudFunctionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailToFetch }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch preferences');
      }

      const data = await response.json();
      setPreferences(data.preferences || {});
      setActiveEmail(emailToFetch);
      setLoading(false);
    } catch (err) {
      setError(err.message || 'An error occurred');
      setLoading(false);
    }
  };

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    if (inputEmail && inputEmail.trim() !== "") {
      fetchPreferences(inputEmail.trim());
    }
  };

  const handleToggle = (key) => {
    setPreferences(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const formatPreferenceName = (name) => {
    return name.replace(/([A-Z])/g, ' $1').trim();
  };

  const savePreferences = async () => {
    try {
      setSaving(true);
      const response = await fetch(cloudFunctionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: activeEmail,
          updatedPreferences: preferences
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save preferences');
      }

      const result = await response.json();
      console.log('Save Response:', result);

      setSaveSuccess(true);
      
      // Resetting save success status after 3 seconds
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (err) {
      console.error('Error saving preferences:', err.message || err);
      setError(err.message || 'Error saving preferences');
    } finally {
      setSaving(false);
    }
  };

  const renderEmailInput = () => {
    return (
      <div className="email-input-container">
        <h2>Manage Email Preferences</h2>
        <p>Enter your email address to manage your preferences:</p>
        <form onSubmit={handleSubmitEmail} className="email-form">
          <input
            type="email"
            value={inputEmail}
            onChange={(e) => setInputEmail(e.target.value)}
            placeholder="Your email address"
            required
          />
          <button type="submit" className="arrow-button">
            →
          </button>
        </form>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="unsubscribe-container loading">
        <div className="loader"></div>
        <p>Loading your email preferences...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="unsubscribe-container error">
        <h2>Error</h2>
        <p>{error}</p>
        {renderEmailInput()}
      </div>
    );
  }

  if (!activeEmail) {
    return (
      <div className="unsubscribe-container">
        {renderEmailInput()}
      </div>
    );
  }

  return (
    <div className="unsubscribe-container">
      <h2>Email Preferences</h2>
      <p className="email-display">Email: {activeEmail}</p>
      
      <div className="preferences-list">
        {Object.keys(preferences).map(key => (
          <div key={key} className="preference-item">
            <span className="preference-name">{formatPreferenceName(key)}</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={preferences[key]}
                onChange={() => handleToggle(key)}
              />
              <span className="slider round"></span>
            </label>
          </div>
        ))}
      </div>

      {saveSuccess && (
        <div className="success-message">
          Your preferences have been updated successfully!
        </div>
      )}

      <button 
        className="save-button" 
        onClick={savePreferences} 
        disabled={saving}
      >
        {saving ? 'Saving...' : 'Save Preferences'}
      </button>
    </div>
  );
};

export default Unsubscribe;