import { initializeApp } from 'firebase/app'
// import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getFirestore } from '@firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_BUCKET,
    messagingSenderId: process.env.REACT_APP_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}
/* const firebaseConfig = {
    apiKey: 'AIzaSyBECtJEjuqwmrML214t96m4FQr0ufubl1w',
    authDomain: 'chat-dca4d.firebaseapp.com',
    projectId: 'chat-dca4d',
    storageBucket: 'chat-dca4d.appspot.com',
    messagingSenderId: '898931659324',
    appId: '1:898931659324:web:567af65410224ca9a7e939',
} */

const app = initializeApp(firebaseConfig)

// initializeAppCheck(app, {
//     provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_CHECK_ID),
//     isTokenAutoRefreshEnabled: true
// });

export const analytics = getAnalytics(app)
export const firestore = getFirestore(app)
export const perf = getPerformance(app)
